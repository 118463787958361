.feed-type-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.letter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  
  &.free-bundle {
    background-color: #4CAF50;
  }
  
  &.indeed-campaign {
    background-color: #FF9800;
  }
  
  &.small {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
  
  &.medium {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  
  &.large {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
} 