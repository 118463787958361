.FeedsList {
  .tableHeader li {
    font-size: medium;
    &:nth-child(3) {  // Type column (3rd column)
      text-align: center;
    }
  }
  line-height: 185%;

  ul {
    grid-template-columns: 0.3fr 1.3fr 0.6fr 1fr 0.7fr 1.3fr 0.3fr 0.2fr 0.1fr;
  }

  .SingleFeed {
    .inactive {
      * {
        opacity: 0.7;
      }
      .advancedButt,
      .editButt {
        * {
          opacity: unset;
        }
      }
    }
    .editButt,
    .settingsButt {
      position: relative;
      top: 6px;
    }
    .activateButt {
      img {
        margin-top: 8px;
        width: 15px;
        height: 15px;
      }
      .check {
        width: 25px;
        height: 25px;
      }
    }

    .feedType {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .advancedButt {
      position: relative;
      font-weight: bold;
      padding-bottom: 10%;
    }

    .importRunsClickable {
      p {
        display: inline-block;
      }

      p:last-child {
        color: blue;
        font-size: smaller;
      }

      p:first-child {
        color: blue;
        width: 70px;
      }

      .noImportRunLine {
        margin-bottom: 4px;
      }
    }
  }

  .SingleFeedExport {
    ul {
      grid-template-columns: 0.3fr 1.3fr 1fr 0.7fr;
    }
  }

  .loadFeeds {
    float: right;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    width: 100%;
  }

  .searchContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
  }

  .table {
    .no-results {
      padding: 20px;
      text-align: center;
      color: #666;
      font-style: italic;
      background-color: #f9f9f9;
      border-radius: 4px;
      margin-top: 20px;
    }
  }
}

.AdvancedDropDown {
  background-color: $midGrey;
  z-index: 999;
  position: absolute;
  right: -350%;
  top: 20px;
  width: 200px;
  padding: 5px 7px;
  border-radius: calc($basicRadius / 4);
  opacity: 0.9;

  .SingleFeedExport & {
    right: 0%;
  }

  ul {
    display: flex;
    flex-direction: column;

    .delete {
      color: red;
    }

    li {
      font-weight: 400;

      &:hover {
        font-size: large;
      }
    }
  }
}

.ImportRuns,
.LastImportRun {
  ul {
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
  }
}

.JobPostings {
  ul {
    grid-template-columns: 0.2fr 0.25fr 1.5fr 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  h1 {
    margin-bottom: $basicDistance;
  }

  .externalExport {
    margin-left: 10px;
  }
}

.JobPostingImports {
  ul {
    grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.4fr 0.2fr 0.2fr 0.8fr 0.3fr;
  }

  h1 {
    margin-bottom: $basicDistance;
  }
}
.ImportRuns {
  .manualTrigger {
    text-align: right;
  }
  .triggerTest {
    margin-top: 10px;
  }
  .nextImportRun {
    div {
      margin-top: 10px;
      text-align: center;
      font-size: x-large;
    }
  }
  .next {
    div {
      display: inline-block;
    }
  }
  .status {
    color: green;
    margin-right: 20px;
  }

  .rows {
    position: relative;
  }

  .abort {
    width: 40px;
    position: absolute;
    right: 0px;
    left: 18.5%;
    top: 3px;
    cursor: pointer;
  }

  .filterContainer {
    margin-bottom: $basicDistance;
    
    .dateFilter {
      display: flex;
      align-items: center;
      gap: 10px;
      
      label {
        font-weight: bold;
      }
      
      input[type="date"] {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      .clearFilter {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        
        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  }
}
