.feed-search {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;

  .search-input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .search-input {
    padding: 12px 18px;
    border: 1px solid #ddd;
    border-radius: 25px;
    font-size: 14px;
    width: 100%;
    transition: all 0.3s;
    padding-right: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
    }
  }

  .reset-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-indicator {
    margin-left: 10px;
    font-size: 14px;
    color: #666;
  }
} 